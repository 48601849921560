import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BG from "../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"

const BaordOfDirectors = () => {
  return (
    <Layout page="bod">
      <SEO title="About" />
      <div
        id="about"
        style={{
          background: `url(${BG}) repeat`
        }}>
        <div id="title" className="green-bg vertical-padding-smaller center">
          <div className="container">
            <div className="horizontal-padding">
              <h1>BOARD OF DIRECTORS</h1>
            </div>
          </div>
        </div>
        <div className="vertical-padding-small">
          <div className="container">
            <div className="columns">
              <div className="one_half column">
                <p>
                  <b>Vinesh Bhindi</b><br />Chairperson<br />Managing Director<br />Mazda Australia Pty Limited
                  <br /><br />
                  <b>Anthony Groves</b><br />Dealer Principal<br />Mornington Mazda
                  <br /><br />
                  <b>Stephen Kroker</b><br />Retired Partner<br />Gadens Lawyers
                  <br /><br />
                  <b>Peter James</b><br />Content Producer<br />Sesimi
                </p>
              </div>
              <div className="one_half column">
                <p>
                  <b>Peter Caldwell</b><br />Partner<br />Deloitte, Assurance and Advisory
                  <br /><br />
                  <b>Louise Kuramoto</b><br />Executive Officer<br />Jack Brockhoff Foundation
                  <br /><br />
                  <b>David Freedman</b><br />Solicitor
                  <br /><br />
                  <b>Johanna Platt</b><br />Interim CFO<br />ISPT
                  <br /><br />
                  <b>Karen Perret</b><br />Senior Director and Chief Counsel Australia, New Zealand & Japan<br />Mondelez International
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BaordOfDirectors
